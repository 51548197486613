import {
    h, FunctionalComponent
} from "preact";
import "npm:bootstrap-icons/font/bootstrap-icons.css"

export type BiData = {
    icon: BiIcon;
    style?: string;
}

export enum BiIcon {
    // How
    // Why
    CalendarEvent = "calendar-event", // When
    Cart = "cart",
    Check = "check",
    ConeStriped = "cone-striped", // Disaster Other
    ExclamationTriangle = "exclamation-triangle",
    Fire = "fire", // Disaster Response
    GeoAlt = "geo-alt",
    Hurricane = "hurricane", // Disaster Response
    People = "bi-people", // Who2
    PersonCircle = "person-circle", // Who
    PinMap = "pin-map", // Where
    Tag = "tag", // What
    Tornado = "tornado", // Disaster Response
}

export const Bi: FunctionalComponent<BiData> = (props) => {
    return <i class={"bi bi-" + props.icon} style={props.style + ""}></i>;
};

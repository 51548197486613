import {
    h, FunctionalComponent
} from "preact";
import "npm:font-awesome/css/font-awesome.min.css";

export type FaData = {
    icon: FaIcon;
}

export enum FaIcon {
    CartPlus = "cart-plus",
    ShoppingCart = "shopping-cart",
}

export const Fa: FunctionalComponent<FaData> = (props) => {
    return <i class={"fa fa-" + props.icon}></i>;
};

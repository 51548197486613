import { FunctionalComponent, h } from "preact";
import { BadgeData } from "../badge/badge_data";
import { NavbarBadge } from "../badge/navbar_badge";
import { Fa, FaIcon } from "../font_awesome/fa";
// ^^^ Preact Dependencies ^^^

export enum NavItemStatus {
    Current = "active",
    Normal = "",
    Disabled = "disabled",
}

export const NavItem: FunctionalComponent<{
    icon?: FaIcon;
    caption: string;
    href: string;
    status?: NavItemStatus;
    badge?: BadgeData;
}> = ({
    icon,
    caption,
    href,
    status = NavItemStatus.Normal,
    badge,
}: {
    icon?: FaIcon;
    caption: string;
    href: string;
    status?: NavItemStatus;
    badge?: BadgeData;
}) => (
        <li class="nav-item" style="white-space: nowrap;">
            <a
                class={["nav-link", status].filter((a) => a).join(" ")}
                aria-current="page"
                href={href}
            >
                {(() => {
                    if (icon) {
                        return <Fa icon={icon} />;
                    }
                })()}
                {caption}
                {(() => {
                    if (badge) {
                        return <NavbarBadge {...badge} />;
                    }
                })()}
            </a>
        </li>
    );

// Vendor
import { h, FunctionalComponent } from "preact";

// Application Specific
import { FormatTagUrl } from "../tag_url_formatter/tag_url_formatter";
import Logo from "./LogoWhite.svg";
import { NavDivider } from "./nav_divider";
import { NavItem, NavItemStatus } from "./nav_item";
import { PresetColor } from "../color/preset_color";
import { FaIcon } from "../font_awesome/fa";

export const TitleBar: FunctionalComponent<{
    main_category_vec: string[];
    secondary_category_vec: string[];
}> = ({
    main_category_vec,
    secondary_category_vec,
}: {
    main_category_vec: string[];
    secondary_category_vec: string[];
}) => (
        <header
            class="navbar sticky-top navbar-expand-lg navbar-dark"
            style="background-color: #025a19;padding:0rem .5rem;"
        >
            <div class="container-fluid" style="padding-left: 0;">
                <a class="navbar-brand" href="/">
                    <img
                        src={Logo}
                        alt="EI Logo"
                        style="height:1em;top:-0.1em;position:relative;margin-right:0.25em;"
                    />
                    emeraldinspirations.xyz
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon" />
                </button>
                <div class="collapse navbar-collapse">
                    <span class="navbar-text" style="width:100%;" />
                    <div class="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul class="navbar-nav">
                            {main_category_vec.map((item) => {
                                return (
                                    <NavItem
                                        key={item}
                                        caption={item}
                                        href={FormatTagUrl(item)}
                                    />
                                );
                            })}
                            <NavDivider />
                            {secondary_category_vec.map((item) => {
                                return (
                                    <NavItem
                                        key={item}
                                        caption={item}
                                        href={FormatTagUrl(item)}
                                    />
                                );
                            })}
                            <NavDivider />
                            {/* <NavItem
                                key="cart"
                                caption=""
                                href="/cart"
                                icon={FaIcon.ShoppingCart}
                                badge={{ caption: "3", color: PresetColor.Danger, }}
                            /> */}
                            <NavItem
                                caption="Public"
                                href="#"
                                status={NavItemStatus.Disabled}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );

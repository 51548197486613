import { h, FunctionalComponent } from "preact";
import { ArticleSource, SourceData } from "../article_source/source";
import { Tag } from "./tag";
import { TagData } from "./tag_data";

export const TagCardFooter: FunctionalComponent<{
    tag_array: TagData[];
    source_array: SourceData[];
}> = ({
    tag_array,
    source_array,
}: {
    tag_array: TagData[];
    source_array: SourceData[];
}) => (
        <small class="card-footer">
            Tags:&nbsp;
            {tag_array.map((tag_data: TagData) => {
                return <ArticleSource data={tag_data} />;
            })}{source_array.map((source_data: SourceData) => {
                return <ArticleSource data={source_data} />;
            })}
        </small>
    );

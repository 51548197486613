import {
    h,
    FunctionalComponent,
    VNode,
    createElement,
    ComponentChild,
    ComponentChildren,
} from "preact";
import { SocialListItem } from "../profile_aside/social_list_item";
import { TagData } from "./tag_data";
import { TagCardFooter } from "./tag_card_footer";
import { SourceData } from "../article_source/source";
// ^^^ Preact Dependencies ^^^

export type ArticleData = {
    author: String;
    updated: String;
    title: String;
    tag_array: TagData[];
    source_array: SourceData[];
};

export const Article: FunctionalComponent<ArticleData> = (props) => (
    <article
        class="card"
        style="margin-bottom: 1em;background-color: rgba(34, 34, 34, 0.75);"
    >
        <div class="card-header">
            <div style="float:right;font-size:75%;line-height:100%;text-align:right;">
                {props.author}
                <br />
                {props.updated}
            </div>
            <h5 class="card-title" style="margin:0;">
                {props.title}
            </h5>
        </div>
        <div class="card-body">
            <p class="card-text">{props.children}</p>
        </div>
        <TagCardFooter tag_array={props.tag_array} source_array={props.source_array} />
    </article>
);

type Link = {
    key: string;
    url: string;
};

// TODO
function parse_param_array(param_array: string): Link[] {
    return param_array.split("|").map((s) => {
        const split = s.split("/");
        return {
            key: split[0],
            url: split[1] || "",
        };
    });
}

export function create_article_from_element(
    elem: Element
): ReturnType<typeof node2vdom> {
    let author = elem.getAttribute("author") || "";
    let updated = elem.getAttribute("updated") || "";
    let title = elem.getAttribute("title") || "";
    let tag_array: TagData[] = parse_param_array(
        elem.getAttribute("tag_array") || ""
    ).map((l) => {
        return {
            caption: l.key,
            key: l.url,
        };
    }); // TODO
    let source_array: SourceData[] = []; // TODO
    let children = Array.from(elem.childNodes).map(node2vdom);
    return (
        <Article
            {...{ author, updated, title, tag_array, source_array, children }}
        />
    );
}

function node2vdom(node: any): ComponentChildren {
    if (node instanceof Element) {
        let attributes = Array.from(node.attributes).reduce(
            (acc, { name, value }) => {
                acc[name] = value;
                return acc;
            },
            {} as Record<string, any>
        );
        let children = Array.from(node.childNodes).map(node2vdom);
        return createElement(node.tagName, attributes, ...children);
    } else if (node instanceof Text) {
        return node.data;
    } else if (node instanceof String || node instanceof Number) {
        console.log("String | Number type found");
        return node;
    } else {
        console.warn("Node: ", typeof node, node);
        throw new Error("Unsupported VDOM source type");
    }
}

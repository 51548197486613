import { h, FunctionalComponent } from "preact";
import { TagData } from "../article/tag_data";
import { Bi, BiIcon } from "../bootstrap_icons/bi";
import './style.css';

export enum SourceSite {
    Blogger,
    DiscGolfCourseReview,
    Facebook,
    GitHub,
    GitLab,
    Instagram,
    LinkedIn,
    Meetup,
    Rumble,
    StackOverflow,
    YouTube,
}

function get_icon_class(site: SourceSite): string {
    if (site == SourceSite.DiscGolfCourseReview) {
        return "dgcr_logo";
    } else if (site == SourceSite.Facebook) {
        return "facebook_logo";
    } else if (site == SourceSite.GitHub) {
        return "github_logo";
    } else if (site == SourceSite.GitLab) {
        return "gitlab_logo";
    } else if (site == SourceSite.Instagram) {
        return "instagram_logo"
    } else if (site == SourceSite.LinkedIn) {
        return "linkedin_logo";
    } else if (site == SourceSite.Meetup) {
        return "meetup_logo";
    } else if (site == SourceSite.Rumble) {
        return "rumble_logo";
    } else if (site == SourceSite.StackOverflow) {
        return "stackoverflow_logo";
    } else if (site == SourceSite.YouTube) {
        return "youtube_logo";
    }
    return "blogger_logo";
}

function get_site_name(site: SourceSite): string {
    if (site == SourceSite.DiscGolfCourseReview) {
        return "Disc Golf Course Review";
    } else if (site == SourceSite.Facebook) {
        return "Facebook";
    } else if (site == SourceSite.GitHub) {
        return "Git Hub";
    } else if (site == SourceSite.GitLab) {
        return "Git Lab";
    } else if (site == SourceSite.Instagram) {
        return "Instagram"
    } else if (site == SourceSite.LinkedIn) {
        return "Linked In";
    } else if (site == SourceSite.Meetup) {
        return "Meetup";
    } else if (site == SourceSite.Rumble) {
        return "Rumble";
    } else if (site == SourceSite.StackOverflow) {
        return "StackOverflow";
    } else if (site == SourceSite.YouTube) {
        return "YouTube";
    }
    return "Blogger";
}

export interface SourceData {
    site: SourceSite;
    href: String;
    active: boolean;
}

export const ArticleSource: FunctionalComponent<{
    data: SourceData | TagData;
}> = ({ data }: { data: SourceData | TagData }) => {
    if ((data as SourceData).active == undefined) {
        return <a
            href={"/" + (data as TagData).key}
            title={"Tag: " + (data as TagData).caption}
            class={"badge rounded-pill bg-info-hover article_source"}
        >
            <Bi icon={BiIcon.Tag} style="font-size:1rem;margin-top:0.1rem;margin-bottom:0.1rem;display:inline-block;" />
            <span class="site_name">{(data as TagData).caption}</span>
        </a>;
    } else if ((data as SourceData).active) {
        return <a
            href={(data as SourceData).href + ""}
            title={"Original Source: " + get_site_name((data as SourceData).site)}
            class={"badge rounded-pill bg-info-hover article_source"}
        >
            <span class={"logo " + get_icon_class((data as SourceData).site)}></span>
            <span class="site_name">{get_site_name((data as SourceData).site)}</span>
        </a>;
    } else {
        return <span
            title={"Original Source: " + get_site_name((data as SourceData).site) + " [Deprecated]"}
            class={"badge rounded-pill bg-grey article_source disabled"}
        >
            <span class={"logo " + get_icon_class((data as SourceData).site)}></span>
            <span class="site_name">{get_site_name((data as SourceData).site)}</span>
        </span>;
    }
}

import { FunctionalComponent, h } from "preact";
// ^^^ Preact Dependencies ^^^

export const NavDivider: FunctionalComponent = () => (
    <li
        class="nav-item"
        style="
        border-left: rgba(250, 250, 250, 0.25) solid 1px;
        border-right: rgba(0, 0, 0, 0.25) solid 1px;
        margin-left: 0.25em;
        margin-right: 0.25em;
    "
    />
);

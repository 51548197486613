// Vendor
import "npm:bootstrap-dark-5/dist/css/bootstrap-night.css";
import "npm:bootstrap/dist/js/bootstrap.min.js"
import { h, render, Fragment } from "preact";

// Application Specific
import "./vps56132.css";
import "./theme.css";
import { StormChasingArticle } from "./article/storm_chasing_article";
import { TitleBar } from "./title_bar/title_bar";
import { Article, create_article_from_element } from "./article/article";
import { ProductTileGroup } from "./product/tile/group";

const [main_tag, dud] = Array.from(document.body.querySelectorAll("main"));
if (!main_tag) {
    throw new Error("Main tag isn't present");
}
if (dud) {
    throw new Error("Extra main tag present");
}

var articles: Element[] = Array.from(main_tag.querySelectorAll("article"));

while (main_tag.firstChild) {
    // Delete the loading spinner
    main_tag.removeChild(main_tag.firstChild);
}

let product_data = {
    image: new URL(
        "../../static/Photo Missing.png",
        import.meta.url
    ).toString(),
    title: "Test Product",
    price: "TBD",
    short_description: "Description",
    long_description: "Long Description",
    url: "/1234-product-title",
};

const MainComponent = () => {
    return (
        <>
            <TitleBar
                main_category_vec={["Software", "Design", "Juggling"]}
                secondary_category_vec={[]}
            />
            {/* <ProductTileGroup data={Array(10).fill(product_data)} /> */}
            <div
                class="d-flex flex-row-reverse"
                style="padding:1em;padding-bottom:0;"
            >
                <main
                    class="container"
                    style="color:#0f0;background-color: rgba(34, 34, 34, 0.75);"
                >
                    {articles.map(create_article_from_element)}
                </main>
            </div>
        </>
    );
};

render(<MainComponent />, document.body, main_tag);

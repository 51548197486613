import {
    h, FunctionalComponent
} from "preact";
import { BadgeData } from "./badge_data";

let width = 12;

export const NavbarBadge: FunctionalComponent<BadgeData> = (props) => {
    return <span
        class={"position-relative translate-middle badge rounded-pill bg-" + props.color}
        style="left:0.5rem;top:1rem;"
    >
        {props.caption}
    </span>;
}
